import logo from './logo.svg';
import './App.css';

import React, { useEffect, useState } from 'react'

import { Button } from 'antd'
import axios from 'axios'

function App() {

    const [user, setUser] = useState({})

    return (
        <div className="App">
            <Button type='primary' onClick={() => {
                axios.get('https://apiuce.eliagersys.com/api/ObtenerMenu')
                    .then(res => {
                        setUser(res.data)
                    })
            }}>
                Get User Info
            </Button>

            <Button type='primary' onClick={() => {
                axios.get('api/ObtenerMenu')
                    .then(res => {
                        setUser(res.data)
                    })
            }}>
                prueba2
            </Button>

            <Button type='primary' onClick={() => {
                axios.get('api/users/me')
                    .then(res => {
                        setUser(res.data)
                    })
            }}>
                prueba3
            </Button>


            <div>{user.name}</div>
        </div>
    );
}

export default App;
